
import { Component, Watch } from 'vue-property-decorator';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { RouteNames } from '@/enums/routes/RouteNames';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import { NewRouteConfiguration } from '@/interfaces/components/cms/NewRouteConfiguration';
import { generateGivenEntityData, openEntityIndex, setRouteEntity } from '@/helpers/CmsShowHelper';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import CmsUserTable from '@/components/views/CmsShow/CmsUserTable.vue';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import NotAllowedComponent from '../components/views/settings/NotAllowedComponent.vue';
import ref from 'vue';

@Component({
    name: 'CmsUsers',
    components: { CmsUserTable, SettingsTopBar, NotAllowedComponent },
})
export default class CmsUsers extends mixins<UserRightsMixin>(UserRightsMixin) {
    private entityName: null | CmsEntityTypes = null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private backRoute: RouteNames = RouteNames.settings;
    private showArchived: boolean = false;
    private createNewUserEvent: EventBusEvents = EventBusEvents.createNewUserButton;
    private userHasRights: boolean = false;

    private get currentEntityData() {
        return generateGivenEntityData(CmsEntityTypes.Users);
    }

    private get isAllowedToSearch() {
        return this.entityName === CmsEntityTypes.PostCodeConnections;
    }

    private goToEntityIndex(newRouteConfiguration: NewRouteConfiguration) {
        openEntityIndex(newRouteConfiguration, this.entityName);
    }

    @Watch('$route', { deep: true, immediate: true })
    private async onRouteChange() {
        this.loadingOverlay.start();
        try {
            this.entityName = await setRouteEntity(this.$route);
        } catch {
            this.$router.push({ name: RouteNames.home });
            return;
        } finally {
            this.loadingOverlay.stop();
        }
    }
    private toggleFilter(e: any) {
        this.showArchived = e.key === 'archived';
    }
    private created() {
        // @ts-ignore
        EventBus.$on(EventBusEvents.createNewUserButton, (payload: any) => {
            this.onCreateNew();
        });
        if (this.canViewUsersTab) {
            this.userHasRights = true;
        }
    }
    private async onCreateNew() {
        if (this.entityName == null) {
            return;
        }
        this.$router.push({
            name: RouteNames.cmsIndex,
            params: {
                entityName: this.entityName,
                entityId: 'new',
            },
        });
    }
}
